import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
} from 'react';

import Icon from './Icon';
import Text from './Text';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  TextInputStyles & {
    prefixIcon?: IconDefinition | string;
    prefixText?: string;
    prefixIconClassName?: string;
    suffixIcon?: IconDefinition | string;
    suffixIconClassName?: string;
    inputClassName?: string;
    prefixElement?: JSX.Element;
    suffixElement?: JSX.Element;
  };

export type TextInputStyles = VariantProps<typeof textInputStyles> &
  VariantProps<typeof textInputSize>;
const textInputStyles = cva(
  'flex flex-1 items-center gap-4 rounded-lg border px-4 transition-colors',
  {
    variants: {
      variant: {
        light:
          'border border-jb-neutral-500 bg-neutral-100 text-neutral-1000 focus-within:border-primary-500',
        primary:
          'border-transparent bg-primary-900 focus-within:border-primary-500 ',
        'neutral-1000':
          'border-transparent bg-neutral-1000 focus-within:border-primary-500 ',
        'neutral-900':
          'border-transparent bg-neutral-900 focus-within:border-primary-500 ',
        secondary:
          'border-transparent bg-secondary-500/20 focus-within:border-secondary-500 ',
        grayOutline:
          'border border-neutral-600 bg-neutral-1000 focus-within:border-neutral-100 hover:border-neutral-300',
        transparentNeutralBorder: 'border border-neutral-500',
        'jb-white':
          'border border-neutral-500 bg-jb-neutral-100 text-jb-neutral-1000 focus-within:border-jb-primary-500',
        transparent:
          'rounded-lg border-primary-600 bg-neutral-100 text-black placeholder:text-neutral-500 focus-within:border-neutral-300 hover:border-primary-900 active:bg-neutral-100 disabled:border-transparent',
        jobboardGrayBackgroud:
          'rounded-md !border border-jb-neutral-500 bg-jb-neutral-200 !placeholder-[#636363] focus-within:border-primary-500',
      },
      isError: {
        true: '!border-error  !text-error',
      },
    },
    compoundVariants: [
      { isError: true, variant: 'primary', className: '!bg-error-36%' },
      { isError: true, variant: 'neutral-1000', className: '!bg-error-36%' },
      { isError: true, variant: 'neutral-900', className: '!bg-error-36%' },
    ],
    defaultVariants: {
      variant: 'primary',
      isError: null,
    },
  }
);
const textInputSize = cva('', {
  variants: {
    sizeVariant: {
      xs: 'py-2 text-body-small',
      sm: 'py-3 text-body-small',
      base: 'py-3 text-body',
    },
  },
  defaultVariants: {
    sizeVariant: 'base',
  },
});

const TextInput = (
  {
    variant,
    isError,
    sizeVariant,
    prefixIcon,
    prefixText,
    prefixIconClassName,
    inputClassName,
    suffixIcon,
    suffixIconClassName,
    prefixElement,
    suffixElement,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div
      className={classNames(
        'relative overflow-hidden ',
        textInputStyles({ variant, isError }),
        {
          [props.className!]: props.className !== undefined,
          'cursor-not-allowed text-neutral-400 !border-neutral-800 !bg-neutral-900':
            props.disabled && !isError,
        }
      )}
    >
      {prefixElement}
      {prefixIcon && <Icon icon={prefixIcon} className={prefixIconClassName} />}
      {prefixText && (
        <Text className="text-neutral-500" variant="body-small">
          {prefixText}
        </Text>
      )}
      <input
        type="text"
        {...props}
        className={classNames(
          textInputSize({ sizeVariant }),
          'peer w-full flex-1 autofill:z-10 bg-transparent outline-none disabled:cursor-not-allowed',
          inputClassName
        )}
        ref={ref}
      />
      {suffixIcon && <Icon icon={suffixIcon} className={suffixIconClassName} />}
      {suffixElement}
      <span className="absolute left-0 top-0 z-0 hidden h-full w-full !shadow-[#e8f0fe] peer-autofill:block peer-autofill:shadow-full" />
    </div>
  );
};

export default forwardRef(TextInput);
